// 1カラム化
.is--about{
  .hybs-one-column(100%);
}
.is--about{
  .detailfooter,.detailFooter{
    display: none;
  }
}

.is--about .mainImg{
  &Inner{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    background: url('/asset/img/about/about_main_img@2x.jpg') center / cover no-repeat;
    &:before{
      content: '';
      position: absolute;
      top: 0; left: 0;
      .size(100%,400px);
      background: url('/asset/img/about/about_main_sc@2x.png') center / contain no-repeat;
      z-index: 0;
    }
  }
  h2{
    &:extend(.c-cont-title all);
    color: @body-color;
    position: relative;
    z-index: 1;
  }
}
.is--about .detailFooter{
  @media @xsmall {
    margin-bottom: 20px;
  }
}

.p-about-nav{
  &:extend(.c-btnNav all);
}

// 共通
.p-about{
  &__title{
    &:extend(.c-title-border-bottom-ja all);
  }
}

// プロフィール
.p-profile{
  &__row{
    display: flex;
    @media @small-up {
      margin-top: 80px;
      margin-bottom: 100px;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    @media @xsmall {
      flex-direction: column;
      margin-bottom: 50px;
    }
    max-width: 1040px;
    padding: 0 40px;
    margin: auto;
  }
  &__photo{
    position: relative;
    @media @small-up {
      &:before{
        content: '';
        position: absolute;
        top: -62px; right: -60px;
        display: block;
        background: url('/asset/svg/square.svg') center no-repeat;
        background-size: 100% 100%;
        .square(115px);
      }
    }
    @media @xsmall {
      margin-bottom: 40px;
    }
    img{
      position: relative;
      z-index: 1;
      margin-bottom: 20px;
    }
  }
  &__name{
    text-align: right;
    p{ margin: 0;}
    .title,
    .kana{
      font-size: 1.4rem;
      color: @primary;
      letter-spacing: 0.2em;
    }
    .name{
      font-size: 2.5rem;
      letter-spacing: 0.1em;
      line-height: 1.8;
      small{
        font-size: 1.4rem;
        color: inherit;
        margin-right: 0.7em;
        letter-spacing: 0.3em;
      }
    }
  }
  &__message{
    max-width: 510px;
    margin-bottom: 50px;
    &:last-child{ margin-bottom: 0;}
    &.w-100{
      max-width: 100%;
    }
    &-title{
      font-size: 2.5rem;
      font-weight: 600;
      letter-spacing: 0.05em;
      line-height: 1.5;
      color: @primary;
    }
    &-text{
      word-break: break-all;
      font-size: 1.5rem;
      line-height: 2;
      letter-spacing: 0.1em;
    }
  }
  &__policy{
    counter-reset: num;
    margin-bottom: 50px;
    >p{
      display: flex;
      align-items: center;
      counter-increment: num;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.875;
      letter-spacing: 0.1em;
      @media @xsmall {
        font-size: 1.5rem;
        align-items: flex-start;
      }
      &:before{
        content: counter(num, decimal-leading-zero);
        font-family: 'Roboto', sans-serif;
        font-size: 3.2rem;
        color: @primary;
        margin-right: 1em;
        font-weight: 300;
        line-height: 1;
        @media @xsmall {
          font-size: 2.4rem;
          margin-right: 0.8em;
        }
      }
    }
  }
  &__title{
    &:extend(.c-title-border-bottom-ja all);
    margin-bottom: 45px;
  }
  &__body{
    padding: 80px 40px 70px;
    background-color: #F5F5F5;
    margin-bottom: 150px;
    @media @xsmall {
      padding: 40px;
      margin-bottom: 40px;
    }
  }
  &__text{
    position: relative;
    max-width: 860px;
    margin: auto;
    font-size: 1.5rem;
    line-height: 2;
    letter-spacing: 0.1em;
    z-index: 0;
    @media @small-up {
      &:before,&:after{
        position: absolute;
        display: block;
        content: '';
        z-index: 1;
        background: url('/asset/svg/square.svg') center no-repeat;
        background-size: 100% 100%;
      }
      &:before{
        left: ~"calc(100% + 60px)"; bottom:  ~"calc(100% + 25px)";
        .square(260px);
      }
      &:after{
        right: ~"calc(100% + 85px)"; top: ~"calc(100%  - 50px)";
        .square(180px);
      }
    }
    p{
      letter-spacing: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }
}

// 概要
.p-outline{
  padding: 0 20px;
  margin-bottom: 60px;
  @media @xsmall {
    margin-bottom: 50px;
  }
  &__table{
    border-width: 0;
    max-width: 860px;
    margin: auto;
    &.table-extended,&{
      th,td{
        padding: 15px 20px;
        line-height: 1.8;
        @media @xsmall {
          font-size: 1.2rem;
          padding: 10px;
        }
      }
      th{
        white-space: nowrap;
      }
    }
  }
}

// アクセス
.p-access{
  &__map{
    display: block;
    max-width: 860px;
    margin: 0 auto 80px;
    @media @xsmall {
      margin: 0 40px;
      width: ~"calc(100% - 80px)";
      margin-bottom: 50px;
      &,.entry iframe&{
        height: 350px;
      }
    }
  }
}