@keyframes up_down_arrow {
  25%  { margin-top: -15px; }
  75%  { margin-top: 15px; }
}

footer{
  background-color: #2D2D2D;
  color: @white;
  >a.pagetop{
    display: none;
  }
  .promotion{
    margin-bottom: 0;
    padding: 1em;
  }
  .copyright{
    margin-bottom: 0.5em;
    font-weight: 600;
    a{
      font-size: 1.4rem;
    }
  }
}

.p-gotop{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 20px;
  bottom: 20px;
  .square(75px);
  background: url('/asset/svg/square.svg') no-repeat;
  background-size: 100% 100%;
  font-size: 1.2rem;
  color: @white;
  text-decoration: none;
  &__inner{
    .hide-text;
    position: absolute;
    top: 3px; bottom: 3px; left: 3px; right: 3px;
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }
  &__inner:before{
    display: block;
    content: '';
    .square(15px);
    border: 1px solid #F9B431;
    border-width: 3px 3px 0 0;
    .rotate(-45deg);
  }
  &:hover{
    color: @white !important;
    text-decoration: none !important;
  }
  &:hover &__inner:before{
    animation: up_down_arrow 1s infinite linear;
  }
  &__wrap{
    width: 100%;
    position: fixed;
    position: sticky;
    bottom: 0px;
    z-index: 10;
    .transition(all @transition-fast ease-in-out);
    &.hidden{
      .translateY(200px);
      opacity: 0;
    }
    @media @xsmall{
      display: none;
    }
  }
}

.p-contfooter{}

.p-footer{
  padding: 92px 0px 70px;
  display: flex;
  justify-content: center;
  text-align: left;
  &-info{
    padding: 0;
    width: 360px;
    background-color: #2D2D2D;
    color: @white;
    @media @xsmall {
      text-align: center;
      width: 100%;
      max-width: 100%;
      padding: 50px 20px;
    }
    &__logo{
      display: inline-block;
      .hoverOpacity;
    }
    &__name,&__addr{
      margin: 1em 0;
      margin: 1em 0;
      color: inherit;
      color: inherit;
      font-style: normal;
      &:last-child{ margin-bottom: 0;}
    }
    &__name{
      font-weight: 600;
      @media @small-up {
        letter-spacing: 0;
      }
    }
  }
  &__nav{
    margin-bottom: 1em;
    width: 600px;
    &-list{
      display: flex;
      justify-content: flex-start;
      margin-bottom: 2.2em;
      &:last-child{ margin-bottom: 0;}
      &.is-secondary li{
        font-weight: 400;
      }
      li{
        color: inherit;
        margin: 0;
        font-weight: 600;
        letter-spacing: 0.1em;
        >a{
          margin-right: 2em;
          white-space: nowrap;
        }
        &:last-child >a{ margin-right: 0;}
      }
      .sublist{
        display: flex;
        flex-direction: column;
        margin-top: 0.6em;
        max-width: 154px;
        a{
          .truncate();
          font-size: 1.3rem;
          font-weight: 400;
          line-height: 1.8;
          letter-spacing: normal;
          white-space: nowrap;
        }
        a:before{
          content: '';
          .square(0.8em);
          display: inline-block;
          border: 1px solid @primary;
          margin-right: 0.3em;
        }
      }
    }
    a{
      display: inline-block;
      margin: 0;
      color: inherit;
      &:hover, &:focus{
        text-decoration: none;
        color: shade( @white, 20%);
      }
    }
  }
}