.p-header{
  display: flex;
  align-items: flex-end;
  padding: 13px 0;
  &__logo{
    margin: 0 auto 0 0;
  }
  &__tel{
    margin: 0 25px 0 0;
    font-size: 3.2rem;
    .fa{
      margin-right: 0.3em;
      font-size: 0.9em;
    }
    span{
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
    }
    small{
      font-size: 1.2rem;
    }
  }
  .button .fa{
    margin-right: 0.3em;
  }
}

nav#gmenu{
  border: solid #BCC6C6;
  border-width: 1px 0;
  ul{
    margin: 0 auto;
    display: flex;
    border-width: 0;
    li{
      position: relative;
      flex-grow: 1;
      margin: 0;
      width: auto;
      display: block;
      border-width: 0;
      &:before,
      &:after{
        content: '';
        position: absolute;
        top: 10px; bottom: 10px;
        height: auto;
        width: 1px;
        background-color: #E4E9E9;
      }
      &:before{ left: 0;}
      &:after{ left: 100%;}
      a{
        position: static;
        padding: 16px 10px;
        color: @body-color;
        &:after{
          position: absolute;
          border-bottom: 1px solid #CF5343;
          top: auto; bottom: -1px;
          left: 50%;
          transform: translateX(-50%);
        }
        &:hover,&:focus{
          color: @body-color;
        }
        &.current:after,
        &:hover:after,
        &:focus:after{
          width: 100%;
        }
      }
    }
  }
}

.mainImg{
  margin-bottom: 20px;
  & &Inner{
    display: flex;
  }
  &Inner{
    position: relative;
    align-items: center;
    justify-content: center;
    height: 200px;
    background-image: linear-gradient(150deg, #FF9D00 -10%, #FF8686 100%);
    &:before{
      content: '';
      position: absolute;
      top: 0; left: 0;
      .size(100%,200px);
      background: center / contain no-repeat;
      z-index: 0;
    }
    .is--service &:before{ background-image: url('/asset/img/service/service_main_sc@2x.png');}
    .is--faq &:before{ background-image: url('/asset/img/faq/faq_main_sc@2x.png');}
    .is--info &:before{ background-image: url('/asset/img/news/news_main_sc@2x.png');}
  }
  h2{
    &:extend(.c-cont-title all);
    position: relative;
    z-index: 1;
  }
}