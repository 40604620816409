@keyframes scrollwheel {
  18% {
    height: 15%;
    top: 25%;
  }
  56% {
    height: 50%;
    top: 25%;
  }
  76% {
    height: 15%;
    top: 60%;
  }
}
@keyframes scrollwheel_reverse {
  18% {
    height: 15%;
    top: 60%;
  }
  56% {
    height: 50%;
    top: 25%;
  }
  66%,76% {
    height: 15%;
    top: 25%;
  }
}

// 1カラム化
.is--home{
  .hybs-one-column(100%);
}

.p-mainVisual{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: url('/asset/img/home/top_main_img@2x.jpg') center / cover no-repeat;
  @media @small-up {
    height: ~"calc(100vh - 181px)";
  }
  &__lead{
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    @media @small-up {
      margin-top: -13%;
    }
    >*{
      position: relative;
      z-index: 1;
    }
    img{
      display: block;
      max-width: 100%;
    }
    figcaption{
      font-size: 1.5rem;
      line-height: 1.73;
      margin-top: 2em;
      margin-bottom: 0;
      max-width: 370px;
    }
    &:after{
      content: '';
      position: absolute;
      top: 100%; left: 100%;
      transform: translate(-240px,-45px);
      .size(347px,144px);
      background: url('/asset/img/home/top_title_dream@2x.png') center / contain no-repeat;
      z-index: 0;
      @media @xsmall {
        .size(227px,94px);
        transform: translate(-205px,-42px);
      }
    }
  }
  &__scroll{
    &-btn{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      left: 50%;bottom: 50px;
      transform: translateX(-50%);
      background-color: transparent;
      border-width: 0;
      color: @white !important;
      font-family: 'Roboto';
      font-size: 12px;
      letter-spacing: 0.3em;
      text-indent: .3em;
      cursor: pointer;
      a&:hover, a&:focus{
        color: @white;
        text-decoration: none;
      }
      @media @xsmall {
        display: none;
      }
    }
    &-icon{
      position: relative;
      display: block;
      border-radius: 16px;
      .size(28px,43px);
      border: 1px solid #707070;
      margin-top: .5em;
      &:after{
        content: '';
        position: absolute;
        left: 50%;
        top: 25%;
        width: 1px;
        height: 15%;
        border-radius: 10px;
        background: #707070;
        transform: translateX(-50%);
        animation: scrollwheel_reverse 1.2s infinite;
      }
    }
    &-btn:hover &-icon:after{
    }
  }
}

.p-serviceBlock{
  position: relative;
  overflow: hidden;
  padding: 80px 40px;
  z-index: 1;
  background-color: #F5F5F5;
  @media @xsmall {
    padding: 40px 20px;
  }
  &__title{
    &:extend(.c-title-border-bottom all);
    position: relative;
    margin-bottom: 1em;
    &:before{
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-345px, -150px);
      content: '';
      .size(456px,246px);
      background: url('/asset/img/home/top_service_sc@2x.png') center / contain no-repeat;
      @media @xsmall {
        display: none;
      }
    }
  }
  &:before,&:after{
    position: absolute;
    left: 50%;
    content: '';
    background-image: url('/asset/svg/square.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;
  }
  &:before{
    .square(317px);
    top: -55px;
    transform: translateX(-715px);
  }
  &:after{
    bottom: 55px;
    .square(190px);
    transform: translateX(380px);
  }
  &__grid{
    display: grid;
    grid-template:
      "col1 col2 col3 col4" auto /
       1fr  1fr  1fr  1fr;
    @media @xsmall {
      grid-template:
        "col1 col2" auto
        "col3 col4" auto /
         1fr  1fr;
    }
    gap: 1px;
    max-width: 960px;
    margin: auto;
  }
  &__item{
    position: relative;
    display: block;
    background-color: @white;
    z-index: 0;
    overflow: hidden;
    &:before{
      content: '';
      position: absolute;
      top: 50%; bottom: 50%; left: 50%; right: 50%;
      .square(10px);
      margin-left: -5px;
      margin-top: -5px;
      border-radius: 100%;
      z-index: -1;
      background-image: linear-gradient(150deg, #F7BD1E -10%, #FF8686 100%);
      opacity: 0;
      .transition(all 0.4s ease-in-out);
    }
    &:nth-child(1){ grid-area: col1;}
    &:nth-child(2){ grid-area: col2;}
    &:nth-child(3){ grid-area: col3;}
    &:nth-child(4){ grid-area: col4;}
    a&:hover,a&:focus{
      text-decoration: none;
    }
    &:hover:before,&:focus:before{
      opacity: 0.3;
      transform: scale(40);
    }
    &-icon{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px;
      @media @xsmall {
        padding: 35px;
      }
      .svgicon{
        width: 100%;
        height: 55px;
      }
    }
    &:nth-child(3) &-icon{
      @media @small-up {
        padding: 52.5px 50px;
        .svgicon{
          height: 50px;
        }
      }
    }
    &:nth-child(4) &-icon{
      @media @small-up {
        padding: 45px 50px;
        .svgicon{
          height: 65px;
        }
      }
    }
    &-desc{
      margin: 0;
      padding: 0 43px 43px;
      @media @xsmall {
        padding: 0 15px 20px;
      }
      dt{
        font-size: 2rem;
        line-height: 1.35;
        text-align: center;
        @media @xsmall {
          font-size: 1.8rem;
        }
      }
      dd{
        margin-top: 2em;
        font-size: 1.4rem;
        @media @xsmall {
          font-size: 1.3rem;
          margin-top: 1.5em;
        }
      }
    }
  }
  &__btnwrap{
    text-align: center;
    margin-top: 45px;
    .button{
      height: auto;
      width: 260px;
      font-weight: 300;
      font-size: 1.5rem;
      span{
        vertical-align: middle;
        font-family: 'Roboto', sans-serif;
      }
      .fa:last-child{
        margin-left: 0.5em;
      }
    }
    .button-large{
      padding: 1.5rem 2.8rem;
    }
  }
}

.p-aboutBlock{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-image:
    linear-gradient(150deg, fade(#FF9D00,92%) -10%, fade(#FF8686,92%) 100%),
    url('/asset/img/home/top_about_img@2x.jpg');
  background-size: cover;
  background-position: center;
  color: @white;
  padding: 0 40px;
  margin-bottom: 70px;
  @media @small-up {
    min-height: 560px;
  }
  &__inner{
    position: relative;
    padding: 70px 0 50px;
    width: 100%;
    max-width: 960px;
    margin: auto;
    z-index: 1;
    @media @xsmall {
      padding: 30px 0 50px;
    }
    &:after{
      position: absolute;
      content: '';
      width: 100%;
      height: 394px;
      top: 100%; left: 0;
      transform: translateY(-315px);
      background: url('/asset/img/home/top_about_sc@2x.png') left center / contain no-repeat;
      z-index: -1;
    }
  }
  &__title,&__title small,&__lead{
    color: inherit;
  }
  &__title{
    font-size: 2.7rem;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.2em;
    line-height: 1.75;
    .homeContents &{
      padding: 0;
    }
    small{
      display: block;
      letter-spacing: 0.2em;
      font-weight: 1.3rem;
    }
  }
  &__lead{
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.75;
    max-width: 590px;
    margin-bottom: 50px;
    letter-spacing: 0;
  }
  .button{
    height: auto;
    width: 260px;
    font-weight: 300;
    font-size: 1.5rem;
    span{
      vertical-align: middle;
      font-family: 'Roboto', sans-serif;
    }
    .fa:last-child{
      margin-left: 0.5em;
    }
  }
  .button-large{
    padding: 1.5rem 2.8rem;
  }
}

.p-infoBlock{
  margin-bottom: 60px;
  #main > &.block .element_block > a.wrapLink{
    &:hover{
      background-color: transparent;
    }
  }
  .catetitle{
    display: inline-block;
    background: @dark;
    padding: 2px 10px;
    margin: 5px 0;
    color: @white;
    & a,& a:visited:not(.button){
      color: @white;
    }
  }
  .text-center{
    text-align: center;
  }
  .button{
    height: auto;
    width: 260px;
    font-weight: 300;
    font-size: 1.5rem;
    &.button-large{
      padding: 1.5rem 2.8rem;
    }
    span{
      vertical-align: middle;
      font-family: 'Roboto', sans-serif;
    }
    .fa:last-child{
      margin-left: 0.3em;
    }
  }
  @media @xsmall{
    h3.ui-bar a{
      &:extend(.c-title-border-bottom all);
    }
    div.homeContents & h3.ui-bar a{
      &:extend(.c-title-border-bottom all);
      color: @body-color;
      background-color: transparent;
      // font-size: 2.8rem;
      @media @xsmall{
        margin-bottom: 0.5rem;
      }
      >span{ display: none;}
      &:before{
        text-indent: 0;
        content: '';
        display: inline-block;
        background: url('/asset/img/top/title_news.png') center top / contain no-repeat;
        .size(80px,15px);
      }
    }
    .ui-listview{
      margin-bottom: 29px;
    }
  }
}
.p-infoBlock{
  // background-color: @background-1;
  padding-top: 20px;
  padding-bottom: 35px;
  &__title{
    &:extend(.c-title-border-bottom all);
    margin-bottom: 1em;
  }
  .element_list{
    max-width: 1000px;
    padding: 0 20px;
    margin: 0 auto 30px;
  }
  #main >&.block .contents a.wrapLink{
    .artloop,
    &:first-child .artloop{
      border-top: 1px solid #999;
    }
    &:last-child .artloop{
      border-bottom: 1px solid #999;
    }
  }
  @media @xsmall{
    #hybsMob div&.listContents li > a{
      background-color: transparent;
    }
  }
  #main >&.block .artloop{
    display: flex;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    .date{
      margin-bottom: 0;
      color: #585858;
      font-family: Roboto;
      display: inline-block;
      font-size: 1.7rem;
      width: 130px;
      text-align: center;
      margin: 0px;
      line-height: 1;
      font-weight: 600;
      vertical-align: middle;
    }
    .catetitle{
      .truncate();
      max-width: 120px;
      display: inline-block;
      margin: 0 1em 0 0;
      font-size: 1.4rem;
      &__wrap{
        vertical-align: middle;
        display: inline-block;
        margin: 0 20px 0 0;
        width: 120px;
        font-size: 0;
      }
    }
    h5{
      display: inline-block;
      font-weight: 400;
      line-height: 1.8;
      vertical-align: middle;
    }
  }
}