// 1カラム化
.is--service{
  .hybs-one-column(100%);
}

.is--service .detailFooter{
  @media @xsmall {
    margin-bottom: 20px;
  }
}
.is--service .element_list{
  max-width: 830px;
  margin: 0 auto 100px !important;
  .artloop h5{
    font-size: 2rem !important;
  }
  .artloop{
    padding: 17px 10px !important;
  }
  .wrapLink{
    &:hover,&:focus{
      h5>a{
        text-decoration: none !important;
      }
    }
  }
}
.is--service .element_detail{
  .catchcp{
    display: none;
  }
}

.p-service-nav{
  &:extend(.c-btnNav all);
}

// 共通
.p-service{
  &__title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.4rem;
    font-weight: 600;
    background-color: #F5F5F5;
    margin-bottom: 50px;
    @media @xsmall {
      flex-direction: column;
      margin-bottom: 30px;
    }
    >span{
      display: block;
      text-align: center;
      font-size: inherit;
      font-weight: inherit;
      padding: 15px 40px;
    }
  }
  &__subtitle{
    &:extend(.c-title-border-bottom-ja all);
  }
}


// 業務内容
.p-service{
  &__item{
    &-body{
      max-width: 860px;
      width: 100%;
      margin: 0 auto 100px;
      @media @xsmall {
        padding: 0 40px;
        margin-bottom: 80px;
      }
      h5{
        font-size: 1.8rem;
        font-weight: 600;
      }
      p{
        line-height: 2;
        font-size: 1.5rem;
        letter-spacing: 0.1em;
        &+h5{
          margin-top: 40px;
        }
      }
    }
  }
  &__banner{
    margin-bottom: 100px;
    text-align: center;
    padding: 0 20px;
    .banner{
      display: inline-block;
      img{
        display: block;
        max-width: 100%;
      }
      .hoverOpacity;
    }
  }
}

// 流れ
.p-process{
  counter-reset: step;
  margin-bottom: 80px;
  @media @xsmall {
    margin-bottom: 50px;
  }
  &__step{
    counter-increment: step;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 15px 20px;
    @media @small-up {
      height: 120px;
    }
    @media @xsmall {
      align-items: flex-start;
      display: grid;
      grid-template:
        "num  title" auto
        "num  desc"  auto /
        auto  1fr;
    }
    &:nth-child(odd){
      background-color: #F5F5F5;
    }
    &:before{
      display: block;
      width: 75px;
      content: counter(step, decimal-leading-zero);
      font-family: 'Roboto', sans-serif;
      color: @primary;
      font-size: 40px;
      letter-spacing: 0.05em;
      line-height: 1;
      font-weight: 500;
      grid-area: num;
      @media @xsmall {
        width: auto;
        padding: 0 15px 0 0;
        font-size: 30px;
      }
    }
    dt,dd{
      margin: 0;
      max-width: 100%;
    }
    dt{
      grid-area: title;
      font-size: 2rem;
      letter-spacing: 0.1em;
      line-height: 1.5;
      @media @xsmall {
        margin-bottom: 0.3em;
      }
      @media @small-up {
        width: 205px;
      }
      &:only-child{
        @media @small-up {
          width: 830px;
        }
      }
    }
    dd{
      grid-area: desc;
      font-size: 1.5rem;
      letter-spacing: 0.15em;
      line-height: 1.5;
      @media @small-up {
        width: 625px;
      }
    }
  }
}

// 料金表
.p-price{
  max-width: 1000px;
  padding: 0 20px;
  margin: 0 auto 80px;
  div.entry h2&__title,&__title, div.entry & h2{
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 0.1em;
  }
  table.table-bordered{
    th,td{
      padding: 11px 15px;
      border-color: @dark;
      @media @small-up {
        font-size: 1.5rem;
      }
    }
  }
  .editor-template p{
    font-size: 1.5rem;
  }
}