.c-btnNav{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 75px;
  @media @xsmall {
    margin-bottom: 40px;
  }
  .button{
    height: auto;
    width: 100%;
    max-width: 300px;
    margin: 0 15px;
    padding: 1.25em 1em;
    font-weight: 600;
    font-size: 1.5rem;
    @media @xsmall {
      margin: 0 3px;
      padding: 1em 10px;
      font-size: 1.2rem;
    }
    span{
      vertical-align: middle;
    }
    .fa:last-child{
      margin-left: 0.5em;
      margin-right: -0.5em;
    }
  }
}