// テンプレートURL
@template-directory: '//secure.websapo.jp/_site_template/layout/110_v1';

//import turret override
@import 'turret/turret';

//import components
@import '_components';

//import extend mixins
@import (reference) '_mixins_extend';

//webfont読み込み
@fontfile_path: '../fonts/';
@import 'fonts/font-awesome';
@import 'fonts/Roboto';
// @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

// デフォルトをカスタム用に上書きするスタイル(基本変更しない)
@import '_common';

// CMSベーシックパーツインポート：サイト特有のカスタムも以下ファイル内に記載する
@import 'elements/toolbar';
@import 'elements/breadcrumbs';
@import 'elements/paginations';
@import 'elements/block';

// 各セクションパーツインポート：サイト特有のカスタムも以下ファイル内に記載する
@import 'elements/header';
@import 'elements/side';
@import 'elements/footer';

// サイト特有の全体用カスタマイズを以下に記載
#allbox > .toolbar{
  .hybsSearch{
    border-radius: 100px;
    background-color: transparent;
    border-color: #ccc;
    input{
      background-color: transparent;
      .placeholder(#808080, 1em, inherit, 0.03em, normal);
    }
  }
  .fontChange{
    .button{
      border-radius: 3px;
    }
  }
  .hybsLogin{
    background-color: transparent;
    .button{
      border-radius: 3px;
      border-color: #ccc;
    }
  }
}

@import 'page/home';
@import 'page/about';
@import 'page/service';
@import 'page/faq';