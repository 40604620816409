/*
 * Title
 */

 .c-title-border-bottom{
  color: tint(@body-color,15%);
  text-align: center;
  font-size: 2.7rem;
  letter-spacing: 0.2em;
  text-indent: 0.2em;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 2em;
  span{
    position: relative;
    display: block;
    padding-bottom: 7px;
    &:after{
      position: absolute;
      top: 100%; left: 50%;
      transform: translateX(-50%);
      display: block;
      content: '';
      .size(60px,2px);
      background-color: @primary;
    }
  }
  small{
    display: inline-block;
    margin-top: 1.2em;
    font-size: 1.3rem;
    font-weight: 400;
    color: inherit;
    letter-spacing: 0.2em;
    text-indent: 0.2em;
  }
}

.c-title-border-bottom-ja{
  .c-title-border-bottom;
  span{
    font-weight: 600;
    font-size: 2.4rem;
    font-family: @sans-serif-font-family;
    line-height: 1.7;
  }
}


.c-cont-title{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2.7rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  letter-spacing: 0.2em;
  text-indent: 0.2em;
  line-height: 1.8;
  margin: 0;
  color: @white;
  small{
    letter-spacing: 0.1em;
    text-indent: 0.1em;
    font-weight: 600;
    font-size: 1.5rem;
    color: inherit;
  }
}