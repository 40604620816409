// 1カラム化
.is--faq{
  .hybs-one-column(960px);
  @media @xsmall{
    .ui-content{
      padding-left: 15px;
      padding-right: 15px;
    }
    .listContents .ui-content{
      padding: 0;
    }
  }
}

.is--faq{
  #main .element_list{
    margin-bottom: 80px;
  }
  .element_detail .catetitle{
    margin-bottom: 2em;
  }
}

.p-faq{
  &__title{
    font-size: 20px;
    font-weight: 600;
    &+.catchcp{
      display: none;
    }
  }
}